import axios from "@/services/axios";
import UrlKeeper from '@/services/UrlKeeper.js'
import _ from 'lodash'

class enablingTypesManagment {

  list(page, rowPerPage) {
    const apiUrl = UrlKeeper.getUrl()
    
    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/registries/enablingTypes/list', {
        page: page,
        rowPerPage: rowPerPage
      }).then((response) => {
        if (response.success) {
          resolve(response.results)
        }
      }).catch((err) => {
        reject('error during the call')
      })
    })
  }

  create(enablingType) {
    const apiUrl = UrlKeeper.getUrl()
    
    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/registries/enablingTypes/create', enablingType).then((response) => {
        if (response.success) {
          resolve(response.results)
        }
      }).catch((err) => {
        reject('error during the call')
      })
    })
  }

  update(enablingType) {
    const apiUrl = UrlKeeper.getUrl()
    
    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/registries/enablingTypes/update', enablingType).then((response) => {
        if (response.success) {
          if (enablingType.categoryIds) {
            this.setCategories(enablingType, enablingType.categoryIds.map((el) => {return {id: el}})).then(() => {
              resolve(response.results)
            })
          } else {
            resolve(response.results)
          }
        }
      }).catch((err) => {
        reject('error during the call')
      })
    })
  }

  delete(enablingType) {
    const apiUrl = UrlKeeper.getUrl()
    
    return new Promise((resolve, reject) => {
      if (!enablingType.id) {
        reject('id undefined')
      }

      axios.get(apiUrl + '/registries/enablingTypes/delete', {
        id: enablingType.id
      }).then((response) => {
        if(!response.success) {
          reject(response.results)
        } else {
          resolve(response)
        }
      })
    })
  }

  get(enablingTypeId) {
    const apiUrl = UrlKeeper.getUrl()
    
    return new Promise((resolve, reject) => {
      axios.get(apiUrl + '/registries/enablingTypes/get', { id: enablingTypeId }).then((response) => {
        if (response.success) {
          resolve(response.results)
        }
      })
    })
  }

  setCategories(enablingType, enablingTypeCategories) {
    const apiUrl = UrlKeeper.getUrl()
    
    return new Promise((resolve, reject) => {
      if (!enablingType.id) {
        reject('id undefined')
      } else if (!enablingTypeCategories) {
        reject('enablingTypeCategories undefined')
      } else {
        axios.post(apiUrl + '/registries/enablingTypes/' + enablingType.id + '/categories/set', enablingTypeCategories).then((response) => {
          if (response.success) {
            resolve(response.results)
          } else {
            reject(response.results)
          }
        })
      }
    })
  }


  fields() {
    return Promise.resolve([
      { text: 'Nome', value: 'code' },
      { text: 'Descrizione', value: 'description' },
      { text: 'Categorie', value: 'enablingTypeCategories', type: 'object[]' }
    ])
  }
}

export default new enablingTypesManagment();
